.designsystem-responsive-div {
  height: rem(300);
  display: flex;
  align-items: center;
  justify-content: center;
  --responsive-demo-size: "Default";
  --responsive-demo-color: rgb(200,255,10);
  background-color: var(--responsive-demo-color);
}

.designsystem-responsive-div:after {
  content: var(--responsive-demo-size);
  color: red;
  font-size: rem(60);
}

@media (min-width: 105em) {
  .designsystem-responsive-div {
    --responsive-demo-size: "screen-xxxl";
    --responsive-demo-color: rgb(200,255,255);
  }
}

@media (min-width: 90em) {
  .designsystem-responsive-div {
    --responsive-demo-size: "screen-xxl";
    --responsive-demo-color: rgb(200,255,230);
  }
}

@media (min-width: 80em) {
  .designsystem-responsive-div {
    --responsive-demo-size: "screen-xl";
    --responsive-demo-color: rgb(200,255,200);
  }
}

@media (min-width: 71.875em) {
  .designsystem-responsive-div {
    --responsive-demo-size: "screen-lg";
    --responsive-demo-color: rgb(200,255,170);
  }
}

@media (min-width: 64em) {
  .designsystem-responsive-div {
    --responsive-demo-size: "screen-md";
    --responsive-demo-color: rgb(200,255,140);
  }
}

@media (min-width: 59.375em) {
  .designsystem-responsive-div {
    --responsive-demo-size: "screen-sm-plus";
    --responsive-demo-color: rgb(200,255,100);
  }
}

@media (min-width: 48em) {
  .designsystem-responsive-div {
    --responsive-demo-size: "screen-sm";
    --responsive-demo-color: rgb(200,255,70);
  }
}

@media (min-width: 35.4375em) {
  .designsystem-responsive-div {
    --responsive-demo-size: "screen-xs";
    --responsive-demo-color: rgb(200,255,40);
  }
}

/*
Text Styles

A list of all the available text styles to use in the SCSS.<br>
All the examples below have their correct responsive behaviours.<br>
Please note that this isn't taking the project's design restrictions in account, therefore some combinations might not be allowed together.<br>
In your SCSS:<br>
`@include typeface(sans-serif)` - Will output the project's sans-serif font.<br>
`@include typeset(body-normal)` - Will output the project's body-normal text style, hover the text below to see the individual values.

DOD: wip

Weight: 1

Style guide: core.text-styles
*/
.ds--sans-serif--hero-1 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(40px);
  line-height: 1;
  font-weight: 600;
}

@media (min-width: 35.4375em) {
  .ds--sans-serif--hero-1 {
    font-size: rem(100px);
  }
}

@media (min-width: 64em) {
  .ds--sans-serif--hero-1 {
    font-size: rem(140px);
  }
}

.ds--sans-serif--hero-2 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(30px);
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
}

@media (min-width: 35.4375em) {
  .ds--sans-serif--hero-2 {
    font-size: rem(56px);
  }
}

@media (min-width: 64em) {
  .ds--sans-serif--hero-2 {
    font-size: rem(80px);
  }
}

.ds--sans-serif--hero-3 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(20px);
  line-height: 1;
  font-weight: 600;
}

@media (min-width: 35.4375em) {
  .ds--sans-serif--hero-3 {
    font-size: rem(48px);
  }
}

@media (min-width: 64em) {
  .ds--sans-serif--hero-3 {
    font-size: rem(72px);
  }
}

.ds--sans-serif--hero-4 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(19px);
  line-height: 1;
  letter-spacing: -0.02em;
  font-weight: 600;
  text-transform: uppercase;
}

@media (min-width: 35.4375em) {
  .ds--sans-serif--hero-4 {
    font-size: rem(30px);
  }
}

@media (min-width: 64em) {
  .ds--sans-serif--hero-4 {
    font-size: rem(46px);
  }
}

.ds--sans-serif--hero-5 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(16px);
  line-height: 1.125;
  font-weight: 600;
}

@media (min-width: 35.4375em) {
  .ds--sans-serif--hero-5 {
    font-size: rem(24px);
    line-height: 1.16667;
  }
}

@media (min-width: 64em) {
  .ds--sans-serif--hero-5 {
    font-size: rem(36px);
    line-height: 1.19444;
  }
}

.ds--sans-serif--hero-6 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(12px);
  line-height: 1;
  font-weight: 600;
}

@media (min-width: 35.4375em) {
  .ds--sans-serif--hero-6 {
    font-size: rem(16px);
  }
}

@media (min-width: 64em) {
  .ds--sans-serif--hero-6 {
    font-size: rem(22px);
  }
}

.ds--sans-serif--edge-1 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(22px);
  line-height: 1;
  font-weight: 600;
}

@media (min-width: 64em) {
  .ds--sans-serif--edge-1 {
    font-size: rem(40px);
  }
}

.ds--sans-serif--display-1 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(36px);
  line-height: 1.30556;
  font-weight: normal;
  font-style: normal;
  text-transform: none;
}

@media (min-width: 71.875em) {
  .ds--sans-serif--display-1 {
    font-size: rem(40px);
  }
}

.ds--sans-serif--display-2 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(30px);
  line-height: 1.2;
  font-weight: normal;
  font-style: normal;
}

@media (min-width: 48em) {
  .ds--sans-serif--display-2 {
    font-size: rem(32px);
  }
}

@media (min-width: 64em) {
  .ds--sans-serif--display-2 {
    font-size: rem(36px);
  }
}

.ds--sans-serif--display-3 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(27px);
  line-height: 1.6;
  font-weight: normal;
  font-style: normal;
}

.ds--sans-serif--display-4 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(17px);
  line-height: 1.6;
  font-weight: 600;
  font-style: normal;
}

.ds--sans-serif--display-5 {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(13px);
  line-height: 1.6;
  letter-spacing: 0.07692em;
  text-transform: uppercase;
  font-weight: 600;
  font-style: normal;
}

.ds--sans-serif--body-medium {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(17px);
  line-height: 1.6;
  font-weight: normal;
}

.ds--sans-serif--body-normal {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(15px);
  line-height: 1.6;
  font-weight: normal;
}

.ds--sans-serif--body-small {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(13px);
  line-height: 1.6;
  letter-spacing: 0em;
  font-weight: normal;
}

.ds--sans-serif--blow-up {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(20px);
  line-height: 1.6;
  font-weight: 600;
}

@media (min-width: 48em) {
  .ds--sans-serif--blow-up {
    font-size: rem(15px);
  }
}

@media (min-width: 64em) {
  .ds--sans-serif--blow-up {
    font-size: rem(20px);
  }
}

.ds--sans-serif--trumpet {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(12px);
  line-height: 1.4;
  letter-spacing: 0.08333em;
  text-transform: uppercase;
}

.ds--sans-serif--micro {
  font-family: "Lato", "Gill Sans", sans-serif;
  font-size: rem(11px);
  line-height: 1.6;
}

.ds--serif--hero-1 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(40px);
  line-height: 1;
  font-weight: 600;
}

@media (min-width: 35.4375em) {
  .ds--serif--hero-1 {
    font-size: rem(100px);
  }
}

@media (min-width: 64em) {
  .ds--serif--hero-1 {
    font-size: rem(140px);
  }
}

.ds--serif--hero-2 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(30px);
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
}

@media (min-width: 35.4375em) {
  .ds--serif--hero-2 {
    font-size: rem(56px);
  }
}

@media (min-width: 64em) {
  .ds--serif--hero-2 {
    font-size: rem(80px);
  }
}

.ds--serif--hero-3 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(20px);
  line-height: 1;
  font-weight: 600;
}

@media (min-width: 35.4375em) {
  .ds--serif--hero-3 {
    font-size: rem(48px);
  }
}

@media (min-width: 64em) {
  .ds--serif--hero-3 {
    font-size: rem(72px);
  }
}

.ds--serif--hero-4 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(19px);
  line-height: 1;
  letter-spacing: -0.02em;
  font-weight: 600;
  text-transform: uppercase;
}

@media (min-width: 35.4375em) {
  .ds--serif--hero-4 {
    font-size: rem(30px);
  }
}

@media (min-width: 64em) {
  .ds--serif--hero-4 {
    font-size: rem(46px);
  }
}

.ds--serif--hero-5 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(16px);
  line-height: 1.125;
  font-weight: 600;
}

@media (min-width: 35.4375em) {
  .ds--serif--hero-5 {
    font-size: rem(24px);
    line-height: 1.16667;
  }
}

@media (min-width: 64em) {
  .ds--serif--hero-5 {
    font-size: rem(36px);
    line-height: 1.19444;
  }
}

.ds--serif--hero-6 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(12px);
  line-height: 1;
  font-weight: 600;
}

@media (min-width: 35.4375em) {
  .ds--serif--hero-6 {
    font-size: rem(16px);
  }
}

@media (min-width: 64em) {
  .ds--serif--hero-6 {
    font-size: rem(22px);
  }
}

.ds--serif--edge-1 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(22px);
  line-height: 1;
  font-weight: 600;
}

@media (min-width: 64em) {
  .ds--serif--edge-1 {
    font-size: rem(40px);
  }
}

.ds--serif--display-1 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(36px);
  line-height: 1.30556;
  font-weight: normal;
  font-style: normal;
  text-transform: none;
}

@media (min-width: 71.875em) {
  .ds--serif--display-1 {
    font-size: rem(40px);
  }
}

.ds--serif--display-2 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(30px);
  line-height: 1.2;
  font-weight: normal;
  font-style: normal;
}

@media (min-width: 48em) {
  .ds--serif--display-2 {
    font-size: rem(32px);
  }
}

@media (min-width: 64em) {
  .ds--serif--display-2 {
    font-size: rem(36px);
  }
}

.ds--serif--display-3 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(27px);
  line-height: 1.6;
  font-weight: normal;
  font-style: normal;
}

.ds--serif--display-4 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(17px);
  line-height: 1.6;
  font-weight: 600;
  font-style: normal;
}

.ds--serif--display-5 {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(13px);
  line-height: 1.6;
  letter-spacing: 0.07692em;
  text-transform: uppercase;
  font-weight: 600;
  font-style: normal;
}

.ds--serif--body-medium {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(17px);
  line-height: 1.6;
  font-weight: normal;
}

.ds--serif--body-normal {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(15px);
  line-height: 1.6;
  font-weight: normal;
}

.ds--serif--body-small {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(13px);
  line-height: 1.6;
  letter-spacing: 0em;
  font-weight: normal;
}

.ds--serif--blow-up {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(20px);
  line-height: 1.6;
  font-weight: 600;
}

@media (min-width: 48em) {
  .ds--serif--blow-up {
    font-size: rem(15px);
  }
}

@media (min-width: 64em) {
  .ds--serif--blow-up {
    font-size: rem(20px);
  }
}

.ds--serif--trumpet {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(12px);
  line-height: 1.4;
  letter-spacing: 0.08333em;
  text-transform: uppercase;
}

.ds--serif--micro {
  font-family: "EB Garamond", "Old Standard TT", serif;
  font-size: rem(11px);
  line-height: 1.6;
}

/*
sans-serif

A preview of all the text styles written in the project's sans-serif font.

Markup: <p class="{{modifier_class}}">Example text</p>

.ds--sans-serif--hero-1 - hero-1
.ds--sans-serif--hero-2 - hero-2
.ds--sans-serif--hero-3 - hero-3
.ds--sans-serif--hero-4 - hero-4
.ds--sans-serif--hero-5 - hero-5
.ds--sans-serif--hero-6 - hero-6
.ds--sans-serif--edge-1 - edge-1
.ds--sans-serif--display-1 - display-1
.ds--sans-serif--display-2 - display-2
.ds--sans-serif--display-3 - display-3
.ds--sans-serif--display-4 - display-4
.ds--sans-serif--display-5 - display-5
.ds--sans-serif--body-medium - body-medium
.ds--sans-serif--body-normal - body-normal
.ds--sans-serif--body-small - body-small
.ds--sans-serif--blow-up - blow-up
.ds--sans-serif--trumpet - trumpet
.ds--sans-serif--micro - micro


hidedefault: true

Style guide: core.text-styles.sans-serif
*/
/*
serif

A preview of all the text styles written in the project's serif font.

Markup: <p class="{{modifier_class}}">Example text</p>

.ds--serif--hero-1 - hero-1
.ds--serif--hero-2 - hero-2
.ds--serif--hero-3 - hero-3
.ds--serif--hero-4 - hero-4
.ds--serif--hero-5 - hero-5
.ds--serif--hero-6 - hero-6
.ds--serif--edge-1 - edge-1
.ds--serif--display-1 - display-1
.ds--serif--display-2 - display-2
.ds--serif--display-3 - display-3
.ds--serif--display-4 - display-4
.ds--serif--display-5 - display-5
.ds--serif--body-medium - body-medium
.ds--serif--body-normal - body-normal
.ds--serif--body-small - body-small
.ds--serif--blow-up - blow-up
.ds--serif--trumpet - trumpet
.ds--serif--micro - micro


hidedefault: true

Style guide: core.text-styles.serif
*/
