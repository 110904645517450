@import "../cartridges/app_magasin/cartridge/scss/core/mixin";
@import "../cartridges/app_magasin/cartridge/scss/settings/media-query";
@import "../cartridges/app_magasin/cartridge/scss/settings/typography";

/*
Text Styles

A list of all the available text styles to use in the SCSS.<br>
All the examples below have their correct responsive behaviours.<br>
Please note that this isn't taking the project's design restrictions in account, therefore some combinations might not be allowed together.<br>
In your SCSS:<br>
`@include typeface(sans-serif)` - Will output the project's sans-serif font.<br>
`@include typeset(body-normal)` - Will output the project's body-normal text style, hover the text below to see the individual values.

DOD: wip

Weight: 1

Style guide: core.text-styles
*/

// Returns a string with modifier classes to include in each kss block
@function generate-modifier-classes($font-family) {
    $result: '';
    @each $text-style, $value in $font-types {
        // NOTE: kss wants a newline character between modifiers,
        // that's why there's a linebreak below
        $result: $result + '.ds--#{$font-family}--#{$text-style} - #{$text-style}
';
    }
    @return $result;
}

// Creates arbitary css markup that will style the designsystem examples
@mixin generate-styled-css-classes {
    @each $font-family, $fvalue in $typefaces {
        @each $text-style, $tvalue in $font-types {
            .ds--#{$font-family}--#{$text-style} {
                @include typeface($font-family);
                @include typeset($text-style);
            }
        }
    }
}

// Creates css comments that kss will parse and output in the designsystem.
// By the fact that SCSS variables in parsed into values, but CSS comments are left as is;
// we can include inline variables and function returns in each CSS comment block.
@mixin generate-kss-comment-blocks {
    @each $font-family, $value in $typefaces {
/*
#{$font-family}

A preview of all the text styles written in the project's #{$font-family} font.

Markup: <p class="{{modifier_class}}">Example text</p>

#{generate-modifier-classes($font-family)}

hidedefault: true

Style guide: core.text-styles.#{$font-family}
*/
    }
}

@include generate-styled-css-classes;
@include generate-kss-comment-blocks;
